import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reducers } from '../store/configureTourStore';
import withReducers from '../store/withReducers';

import { Button, Theme } from '@commonsku/styles';
import ErrorBoundary from '../components/ErrorBoundary';
import './LoginPageLayout.css';
import MainSection from './MainSection';
import { createGlobalStyle } from '../components/helpers';

const GlobalStyle = createGlobalStyle();

const GeneralPageLayout = ({ children }) => {
    return (
        <Theme>
            <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }} />
            <ErrorBoundary>
                <MainSection no_sidebar>
                    <div className="main-content csku-styles" style={{ marginTop: 0, padding: 0, paddingBottom: '24px' }}>
                        <div className="row full-width">
                            <div className="row full-width">
                                <div className='login-sticky-container'>
                                    <div className='login-header'>
                                        <div className='login-lft'>
                                            <a href="https://www.commonsku.com/"><img src="/images/commonsku-main-logo-blue.svg" alt="commonsku" /></a>
                                        </div>
                                        <div className='login-right'>
                                            <Button variant="secondary" onClick={() => { window.location.href = 'https://www.commonsku.com'; }}>Learn More</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='login-content'>
                                    <div className='login-content-center'>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        autoClose={3000}
                        hideProgressBar={true}
                    />
                </MainSection>
            </ErrorBoundary>
        </Theme >
    );
};

export default withReducers(GeneralPageLayout, reducers, true);
